export const config = {
  api_base: 'https://prod.diningtek.com/api/v1',
  api_root : 'https://prod.diningtek.com',
  logo_img_root : '/img/pinocchios-logo.png',
  banner_img_root : '/img/pinocchios-banner.png',
  resid : '',
  key_value : 'pinocchiospratt',
  secret_value : 'pinocchiospratt',
  iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3049.502205925901!2d-105.09922578519128!3d40.15337087939663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bf9bc03dd6c2b%3A0x6015560c28602f2a!2s210%20Ken%20Pratt%20Blvd%20%23260%2C%20Longmont%2C%20CO%2080504%2C%20USA!5e0!3m2!1sen!2sin!4v1582804884704!5m2!1sen!2sin',
  facebook_link:'#',
  RestaurantName:"Pinocchios Ken Pratt Order Online"
};
